import {useHistory} from "react-router-dom";
import Cookies from "js-cookie";
import {useNotification} from "../contexts/NotificationContext";
import {MAIN_ERROR_MESSAGES} from "../constants/errorMessages";
import {Box, Button, TextField} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import {useEffect} from "react";

function Fork() {
  const {setIsOpen, setMessage, setIsFailed} = useNotification();
  const ohip = Cookies.get("jwtToken");
  const crew = Cookies.get("crew_token");
  const history = useHistory();
  useEffect(() => {
    if (!ohip || !crew) {
      history.push("/");
      setIsOpen(true);
      setIsFailed(true);
      setMessage(MAIN_ERROR_MESSAGES.tokenExpired);
    }
  }, []);
  return (
    <ul>
      <Box
        sx={{
          margin: "50px auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h3>Choose an application</h3>
        <FormControl
          sx={{
            display: "flex",
            flexDirection: "row",
            mb: 5,
          }}
        >
          <Button
            href={"/upsell/reservation"}
            variant="contained"
            sx={{
              marginRight: "10px",
              bgcolor: "#6c757d",
              "&:hover": {
                bgcolor: "#5c636a",
                color: "#ffffff",
                borderColor: "#5c636a",
              },
            }}
          >
            OHIP Upsell
          </Button>
          <Button
            href={"/crew/reservations"}
            variant="contained"
            sx={{
              marginRight: "10px",
              "&:hover": {
                color: "#ffffff",
                borderColor: "#5c636a",
              },
            }}
          >
            Crew Rooms Management
          </Button>
          <Button
            href={"/wep"}
            variant="contained"
            sx={{
              marginRight: "10px",
              bgcolor: "green",
              "&:hover": {
                bgcolor: "green",
                color: "#ffffff",
                borderColor: "#5c636a",
              },
            }}
          >
            Wifi Email Processing
          </Button>
          <Button
            href={"/uc"}
            variant="contained"
            sx={{
              marginRight: "10px",
              bgcolor: "green",
              "&:hover": {
                bgcolor: "green",
                color: "#ffffff",
                borderColor: "#5c636a",
              },
            }}
          >
            UDF Conversion
          </Button>
        </FormControl>
      </Box>
    </ul>
  );
}

export default Fork;
