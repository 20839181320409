import React, {Component} from "react";
import {Editor} from "@tinymce/tinymce-react";

export class DetailsEditor extends Component {

  render() {
    return (
      <Editor
        apiKey="79mz8wqy2xm8vu5o7n2fu44vlg9te8b475dcrz9o1pwlh877"
        initialValue={this.props.details}
        onChange={this.props.setDetails}
        init={{
          height: 200,
          selector: 'textarea',
          plugins: ['lists'],
          toolbar: 'numlist bullist bold italic underline',
          lists_indent_on_tab: false,
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
        }}
      />
    );
  }
}
